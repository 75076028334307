<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="profileid">
          Edit Profile
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!profileid"
          title="Profile Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="profileid"
          title="Profile Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
        class="panel-title"
        style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars" /> Contact Detail
      </div>
      <el-tabs type="border-card">
        <el-tab-pane label="Personal Information"> 
		<div class="note-label">Note:- Agent info module</div>
		<div class="note-label italic">Note:- Website Info Module, Footer.</div>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="First Name"
                prop="realtor_firstname"
              >
                <el-input
                  v-model="data.realtor_firstname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Last Name"
                prop="realtor_lastname"
              >
                <el-input
                  v-model="data.realtor_lastname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Mobile Number"
                prop="realtor_mobile"
              >
                <el-input
                  v-model="data.realtor_mobile"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Office Phone"
                prop="realtor_officephone"
              >
                <el-input
                  v-model="data.realtor_officephone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Street Address"
                prop="realtor_address"
              >
                <el-input
                  v-model="data.realtor_address"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Country"
                prop="country"
              >
                <el-select v-model="data.country_name" placeholder="Select Country">
              <el-option
              v-for="item in countries"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click="getStates(item.value)"
            ></el-option>
          </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Province/State"
                prop="state"
              >
                 <el-select v-model="data.state_name"  placeholder="Select Region / State">
              <el-option              
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click="getCities(item.value)"
            />
          </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="City"
                prop="city"
              >
                <el-select v-model="data.city_name" placeholder="City">
              <el-option              
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Postal/Zip Code"
                prop="postalcode"
              >
                <el-input
                  v-model="data.postalcode"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Email Address"
                prop="realtor_email"
              >
                <el-input
                  v-model="data.realtor_email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Website Address"
                prop="realtor_website"
              >
                <el-input
                  v-model="data.realtor_website"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="WeChat"
                prop="realtor_wechat"
              >
                <el-input
                  v-model="data.realtor_wechat"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Skype Name"
                prop="realtor_skype_name"
              >
                <el-input
                  v-model="data.realtor_skype_name"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Personal Branding">
		<div class="note-label">Note:- Data Showing In Website Info Module</div>
		<div class="note-label italic">Note:- Website Info Module.</div>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="MLS®/Board user ID"
                prop="realtor_mls_userid"
              >
                <el-input
                  v-model="data.realtor_mls_userid"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Select Board"
                prop="realtor_board"
              >
            <el-select v-model="data.realtor_board" placeholder="Select Profile Board">
              <el-option
                v-for="item in arraayrealtorboard"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Agent ID for Associate Realtor"
                prop="associate_realtors"
              >
                <el-input
                  v-model="data.associate_realtors"
                  type="text"
                  autocomplete="off"
                />
                <span style="float: left;"><b>Note:</b> Agent ID should be comma seprated</span>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="RT ID for Associate Website"
                prop="associate_rtuser"
              >
                <el-input
                  v-model="data.associate_rtuser"
                  type="text"
                  autocomplete="off"
                />
                <span style="float: left;">
                  <b>Note:</b> Website RT ID should be comma seprated</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Professional Title"
                prop="realtor_professional_title"
              >
                <el-input
                  v-model="data.realtor_professional_title"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Team Name"
                prop="realtor_team_name"
              >
                <el-input
                  v-model="data.realtor_team_name"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Agent Description"
                prop="agent_description"
              >
                <el-input
                  v-model="data.agent_description"
                  type="textarea"
                  row="5"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Agent Info Link"
                prop="agentinfo_link"
              >
                <el-input
                  v-model="data.agentinfo_link"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Other Title"
                prop="other_title"
              >
                <el-input
                  v-model="data.other_title"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Other Description"
                prop="other_description"
              >
                <el-input
                  v-model="data.other_description"
                  type="textarea"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Other Info Link"
                prop="otherinfo_link"
              >
                <el-input
                  v-model="data.otherinfo_link"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Images">
		<div class="note-label">Note:- Data Showing In Website Info Module, Agent info module</div>
		<div class="note-label italic">Note:- Website Info Module. Property Detail Page in Agent info Section</div>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item 
          label="Photo"
          prop="image"
        >
          <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
        </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Team Logo"
                prop="realtor_teamlogo"
              >
               <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileListTeam"
            :limit="1"
            :on-preview="handlePreviewTeam"
            :on-remove="handleRemoveTeam"
            :on-success="handleImageUploadSuccessTeam"
            :on-error="handleImageUploadErrorTeam"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Brokerage Logo"
                prop="realtor_brokeragelogo"
              >
               <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileListBrokerage"
            :limit="1"
            :on-preview="handlePreviewBrokerage"
            :on-remove="handleRemoveBrokerage"
            :on-success="handleImageUploadSuccessBrokerage"
            :on-error="handleImageUploadErrorBrokerage"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Brokerage Info">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Brokerage Name"
                prop="other_brokerage_name"
              >
                <el-input
                  v-model="data.other_brokerage_name"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Brokerage Phone"
                prop="other_brokerage_phone"
              >
                <el-input
                  v-model="data.other_brokerage_phone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Brokerage Email"
                prop="other_brokerage_email"
              >
                <el-input
                  v-model="data.other_brokerage_email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Brokerage Website"
                prop="other_brokerage_website"
              >
                <el-input
                  v-model="data.other_brokerage_website"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Office/Brokerage ID"
                prop="office_brokerage_id"
              >
                <el-input
                  v-model="data.office_brokerage_id"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </el-tab-pane>
        <el-tab-pane label=" SEO Info">
		<div class="note-label">Note:- Home Page</div>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Title"
                prop="meta_title"
              >
               <el-input
          v-model="data.meta_title"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Meta Description"
                prop="meta_description"
              >
               <el-input
          v-model="data.meta_description"
          type="textarea"
        />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Keyword"
                prop="meta_keyword"
              >
              <el-input
          v-model="data.meta_keyword"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-form>
</template>
<script>
import ProfileService from "@/api/profile.service";
import authHeader from '@/api/auth-header';
import CountryService from "@/api/country.service";
import TinyMce from "@/components/TinyMce";
  export default {
     components: {
      TinyMce
    },
    data() {
      var checkFname = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the First Name.'));
        } else {
          callback();
        }
      };
      var checkLname = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Last  Name.'));
        } else {
          callback();
        }
      };
      var checkMobile = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Mobile Number.'));
        } else {
          callback();
        }
      };
      var checkAddress = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Street Address.'));
        } else {
          callback();
        }
      };
       var checkCountry = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Country.'));
        } else {
          callback();
        }
      };
       var checkState = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the State.'));
        } else {
          callback();
        }
      };
      var checkCity = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the City.'));
        } else {
          callback();
        }
      };

      var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Email.'));
        } else {
          callback();
        }
      };
      var checkMlsid = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the MLS®/Board user ID.'));
        } else {
          callback();
        }
      };
       var checkBoard = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Select Board.'));
        } else {
          callback();
        }
      };
      
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        profileid: null,
		imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        iflogin:this.$route.query.iflogin,
        fileList:[],
        fileListTeam:[],
        fileListBrokerage:[],
        countries: [],
        arraayrealtorboard:[{
          value: 'mls',
          label: 'MLS® Listing'
        },{
          value: 'ddf',
          label: 'DDF Listing'
        },{
          value: 'other',
          label: 'Other'
        }],
        states:[],
        cities:[],
        data: {
          realtor_firstname: null,
          realtor_lastname:null,
          realtor_mobile:null,
          realtor_officephone:null,
          realtor_address:null,
          realtor_teamlogo:null,
          country:null,
          country_name:null,
          state:null,
          state_name:null,
          city:null,
          city_name:null,
          postalcode:null,
          realtor_email:null,
          realtor_website:null,
          realtor_wechat:null,
          realtor_skype_name:null,
          realtor_mls_userid:null,
          realtor_board:null,
        },
        rules: {
          realtor_firstname: [
            { validator: checkFname, trigger: 'blur' }
          ],
          realtor_lastname: [
            { validator: checkLname, trigger: 'blur' }
          ],  
           realtor_mobile: [
            { validator: checkMobile, trigger: 'blur' }
          ],  
          realtor_address: [
            { validator: checkAddress, trigger: 'blur' }
          ],  
           country: [
            { validator: checkCountry, trigger: 'blur' }
          ],  
          state: [
            { validator: checkState, trigger: 'blur' }
          ], 
           city: [
            { validator: checkCity, trigger: 'blur' }
          ],      
        
          realtor_email: [
            { validator: checkEmail, trigger: 'blur' }
          ],
            realtor_mls_userid: [
            { validator: checkMlsid, trigger: 'blur' }
          ],
          realtor_board:[
            { validator: checkBoard, trigger: 'blur' }
          ],
        }
      };
    },
        watch: {
      data: function () {
        if(!this.data.country_name && this.data.country) {
          this.data.country_name = this.extractNameById(this.data.country, this.countries);
        }
        if(!this.data.state_name && this.data.state) {
          this.data.state_name = this.extractNameById(this.data.state, this.states);
        }
        if(!this.data.city_name && this.data.city) {
          this.data.city_name = this.extractNameById(this.data.city, this.cities);
        }
      },
      countries: function () {
        this.data.country_name = this.extractNameById(this.data.country, this.countries);
      }
      ,states: function () {
        this.data.state_name = this.extractNameById(this.data.state, this.states);
      },
      cities: function () {
        this.data.city_name = this.extractNameById(this.data.city, this.cities);
      },
      
    },
	mounted() {
     this.getcountries();
    this.setImageUploadUrl();
    this.checkiflogin();
      this.getrealtor();
  },
   methods: {
        async getCities(statsid){
        return CountryService.getCities(1,statsid).then(response => {
          this.cities=response.data.data;
        });
      },
     async getStates(countryid){
        return CountryService.getStates(1,countryid).then(response => {
          this.states=response.data.data;
        });
      },
      async getcountries(){
       return CountryService.getcountries().then(response => {
         this.countries=response.data.data;
      });
      },
      checkiflogin() {
        if(this.iflogin=="y"){
          setTimeout(function(){ 
            const elem = document.getElementById('tab-1');
            elem.click(); 
          }, 1000);
        }
      },
      extractNameById(needle, hayStack) {
        let val = null;
        val = hayStack.filter(function(element){
          if(element['value'] == Number(needle)) {
            return element['label'];
          }
        });
        if(val.length) {
          return val[0]['label'];
        }
        return null;
      },
      submitForm(formName) {
         this.data.country = this.data.country_name;
        this.data.state = this.data.state_name;
        this.data.city = this.data.city_name;   
        this.$refs[formName].validate((valid) => {
        if (valid && !this.profileid) {
             return ProfileService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.profileid) {
            return ProfileService.update(this.profileid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },

	getrealtor(){
    return ProfileService.get().then(response => {
        this.data = response.data.data[0];
        this.profileid =response.data.data[0].id;
        this.fileList.push({name:'', url: this.data.image_view});
        this.fileListTeam.push({name:'', url: this.data.realtor_teamlogo_view});
        this.fileListBrokerage.push({name:'', url: this.data.realtor_brokeragelogo_view});

        if(this.data.country){
          this.getStates(this.data.country);
        }
         if(this.data.state){
          this.getCities(this.data.state);
        }        

        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.image = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },

      handleImageUploadSuccessTeam(response, file, fileListTeam) {
        if(response.code == 200) {
          this.data.realtor_teamlogo = response.image_url;
        }
      },
      handleImageUploadErrorTeam(err, file, fileListTeam) {
        console.log(err)
      },
      handlePreviewTeam(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveTeam(file, fileListTeam) {
        console.log(file, fileListTeam)
        //TODO: Delete the file
      },

      handleImageUploadSuccessBrokerage(response, file, fileListBrokerage) {
        if(response.code == 200) {
          this.data.realtor_brokeragelogo = response.image_url;
        }
      },
      handleImageUploadErrorBrokerage(err, file, fileListBrokerage) {
        console.log(err)
      },
      handlePreviewBrokerage(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveBrokerage(file, fileListBrokerage) {
        console.log(file, fileListBrokerage)
        //TODO: Delete the file
      },

    }
  }
</script>
<style lang="scss" scoped>
 .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }
 .titledate {
    color: #2E7D32;
    font-size: 18px;
    padding: 10px 0px 0px 0px;
}
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.el-checkbox{
  float: left;
}
.note-label{font-size: 12px;font-weight:700;margin-bottom: 6px;text-transform: capitalize;text-align:left;}
.italic{font-style: italic;color: #a72222;}
</style>